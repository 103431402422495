import { disableVersionTimes, enableVersionTimes } from '@/api/aqversiontime';

const disableVersionTime = {
	id: 'disableVersionTime',
	selectionType: 'multiple',
	label: 'aqversiontime.actions.disableVersionTime',
	functionality: 'UPDATE_AQVERSIONTIME',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 0;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversiontime.actions.disableVersionTime');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversiontimeid: registries[i].aqversiontimeid
			});
		}

		const data = await disableVersionTimes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

const enableVersionTime = {
	id: 'enableVersionTime',
	selectionType: 'multiple',
	label: 'aqversiontime.actions.enableVersionTime',
	functionality: 'UPDATE_AQVERSIONTIME',
	checkAvailability: function (instance) {
		return instance && instance.disabled === 1;
	},
	runAction: async function (action, model, registries) {
		var title = this.$puiI18n.t('aqversiontime.actions.enableVersionTime');

		var body = [];
		for (let i = 0; i < registries.length; i++) {
			body.push({
				aqversiontimeid: registries[i].aqversiontimeid
			});
		}

		const data = await enableVersionTimes(body);
		if (data.error) {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifyError');
			this.$puiNotify.error(message, title);
		} else {
			this.$puiEvents.$emit('onPui-action-running-ended-' + model.name);
			const message = this.$puiI18n.t('puiaction.notifySuccess');
			this.$puiNotify.success(message, title);
		}
	}
};

export default {
	actions: [disableVersionTime, enableVersionTime]
};
